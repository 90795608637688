<template>
  <div class="check-tips">
    <div class="content" :style="boxWidth">
      <div class="tips">你输入的领取码已被使用，是否再次查看激活卡密</div>
      <div class="flex-row">
        <div class="button cancel" @click="cancel">取消</div>
        <div class="button" @click="confirmCheck">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CheckTips extends Vue {
  @Prop() offsetwidth
 boxWidth = {}
  created() {
    document.body.style.overflow = 'hidden'
     this.boxWidth = {
            width:this.offsetwidth + 'px',
        }
  }
  @Emit()
  cancel() {
    document.body.style.overflow = "";
    return;
  }  @Emit()
  confirmCheck() {
    document.body.style.overflow = "";
    return;
  }
}
</script>

<style lang="scss">
.check-tips{
  transition: all 0.3;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  .content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-sizing: border-box;
    background: linear-gradient(180deg, #e1faf1 0%, #fafffd 100%);
    box-shadow: 0px 0px 10px 0px #001a19;
    border-radius: 16px;
    border: 1.5px solid rgba(255, 255, 255, 0.3);
    .tips{
      height: 72px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      
    }
    .flex-row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button{
        width: 50%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #139796;
        font-size: 14px;
        border-top: 1px solid rgba($color: #139796, $alpha: 0.2);
      }
      .cancel{
        border-right: 1px solid rgba($color: #139796, $alpha: 0.2);
      }
    }
    
  }
}
</style>