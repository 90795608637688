

















import { Toast } from "vant";
import { Component, Emit, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import PayPop from "@/views/destiny-draw/components/destinyFate/PayPop.vue";
import DestinyCartoon from "@/views/weplay-game/DestinyCartoon.vue";
import { delay, scrollTop, systemPay, wechatPay } from "@/utils/common.util";
import { random, cloneDeep, throttle } from "lodash";
import SupplyPackageApi, {
  IOrderStatusDetail,
  IOrderV2,
  ISupplyBox,
  ISupplyDetail,
} from "@/api/supply-package.api";
import { VanToast } from "vant/types/toast";
import WalletPaymentSelector from "@/components/WalletPaymentSelector.vue";
@Component({
  components: {
    WalletPaymentSelector,
    PayPop,
    DestinyCartoon,
  },
})
export default class PlayFate extends Vue {
  @Ref("destiny-cartoon")
  private readonly destinyCartoonRef: DestinyCartoon | undefined;
  @Ref("WalletPaymentSelector")
  private readonly paymentSelectorRef!: WalletPaymentSelector;

  @Watch("show")
  watchShow(e: any) {
    console.log("a:1111", e);
  }
  istrue = true
  @Prop({ default: () => [] })
  private readonly items!: Array<ISupplyBox>;
  @Prop({ default: false })
  private readonly jumpFlag!: boolean;
   

  @Prop({ default: () => null })
  private readonly detail!: ISupplyDetail | null;

  @Prop({ default: 0, required: true })
  private readonly box_id!: number;

  show = false;
  styleState = true;
  popState = false;
  radio = "1";
  fakeMoney = 0;
  number = 0;
  price = 0;
  balance_money = 0;
  timertime = 0;
  @Emit()
  openPop (){
    return
  }

  money: any = {};
  jumpFlagFake = false

  @Emit()
  async handleSkip(){
    this.jumpFlagFake = this.jumpFlag
    this.destinyCartoonRef?.stopAnimation();
    this.jumpFlagFake = true
    this.istrue = true
    document.body.style.overflow = ''
    return
  }
  @Emit()
  openCard(){
    return
  }
  async mounted() {}
  // 动画播放 +
  // __select_choose
  rootClass = "__destiny_fete_frame";

  // 获取抽奖测手气id
  testLuckyDrawId(): number {
    const arr_ids = this.items.map((row) => row.id);
    const random_id = arr_ids[random(0, arr_ids.length - 1)];
    return random_id || 0;
  }

  // 测试手气
  handleTestClick(): void {
    const id = this.testLuckyDrawId();
    this.playAnimation([id], true);
  }
  async playAnimation(
    supply_id: number[],
    test = true,
    detail?: IOrderStatusDetail
  ): Promise<void> {
    this.rootClass = "__destiny_fete_frame __select_choose"; // 高亮
    await this.destinyCartoonRef?.playAnimation(
      supply_id.find(Boolean) || 0,
      async () => {
        const items: ISupplyBox[] = [];
        supply_id.forEach((id) =>
          items.push(cloneDeep(this.items.find((row) => {
            return row.id === id})!))
        );
        this.rootClass = "__destiny_fete_frame";
        this.destinyCartoonRef?.restore();
        if(!this.jumpFlagFake){
           this.success()
           this.jumpFlagFake = false
        }
      }
    );
  }
  @Emit()
   success(){
     return
   }
  @Emit()
  updateCoupon(): void {
    return;
  }
  openTimes = 0;
  couponPricefake = 0;

  PayOrder: IOrderV2 | undefined = undefined;
  PayToast: VanToast | undefined = undefined;
  channel_number: number | undefined = undefined;


  @Emit()
  updateDetailMini(option: Partial<ISupplyDetail>): Partial<ISupplyDetail> {
    return option;
  }
  upgradeList: any = "";
  

  async checkOrder(
    order_no: string
  ): Promise<
    | { supply_id: number[]; detail: IOrderStatusDetail; status: true }
    | { supply_id: number[]; detail: Record<string, any>; status: false }
  > {
    for (let i = 0; i < 5; i++) {
      const status = await SupplyPackageApi.orderStatusV2(order_no).catch(
        console.error
      );
      if (status && [2, 3].includes(status.order_status)) {
        return {
          status: true,
          supply_id: status.supply_id,
          detail: status.detail,
        };
      }
      if (status && Number(status.order_status) > 3) {
        throw new Error("购买失败");
      }
      await delay(1000);
    }
    return {
      status: false,
      supply_id: [0],
      detail: {},
    };
  }

  get payType(): boolean {
    const { pay_type = 0 } = this.detail || {};
    return Boolean(pay_type);
  }

  get drawTotalCount(): 5 | 10 {
    const { five_status = false } = this.detail || {};
    return five_status ? 5 : 10;
  }

  get tenPrice(): number {
    const { totle_price = 0, totle_point = 0 } = this.detail || {};
    return this.payType ? totle_price / 100 : totle_point;
  }

  get freeStatus(): boolean {
    if (this.payType) return false;
    const { has_free_receive = false, is_today_free = false } =
      this.detail || {};
    return !has_free_receive && is_today_free;
  }

  handleScroll(): void {
    scrollTop("#destiny-feed");
  }
}
