<template>
  <div class="card-reword">
    <div class="content"  :style="boxWidth">
      <img
        @click="closePop"
        src="https://vgn-images.oss-cn-beijing.aliyuncs.com/static/market/%E6%8C%89%E9%92%AE-%E5%85%B3%E9%97%AD%20%281%29.png"
        class="close-pop"
        alt=""
      />
      <div class="card-content" >
        <div style="border-radius: 8px;
border: 3px solid #00D1C2;">
        <img
          :style="imgStyle"
          :src="RewordRecord[0].cover"
          alt=""
        />
        </div>
        <div class="title">{{RewordRecord[0].title}}</div>
      </div>
      <div class="phone" :style="heightStyle">
        <div class="card-ser">{{result.voucher_code}}</div>
        <div class="copy" @click="handleCopy(result.voucher_code)">复制</div>
      </div>
      <div class="time">领取时间：{{result.receive_time}}</div>
      <div class="dashed"></div>
      <div class="tips-pop">[激活码短信同时已发送至您的手机，请登录Steam激活游戏]</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { copy,momentFormat } from "@/utils/common.util";
import {Toast} from 'vant'
@Component({
  components: {},
})
export default class CardReword extends Vue {
    @Prop() wideHigh
    @Prop()RewordRecord
   @Prop()result
   @Prop() offsetwidth
    imgStyle = {
      borderRadius:'5px',
      objectFit:'cover',
      width:'150px',
      height:'150px'
    }
    heightStyle = {
      height:0
    }
    realwideHigh = 0
    boxWidth = {}
  created() {
    this.boxWidth = {
            width:this.offsetwidth + 'px',
        }
    this.realwideHigh = this.wideHigh 
    document.body.style.overflow = "hidden";
    this.heightStyle.height = this.realwideHigh*0.2 + 'px'
    this.realwideHigh = this.realwideHigh - 91
    this.imgStyle = {
      borderRadius:'5px',
      objectFit:'cover',
      width:this.realwideHigh + 'px',
      height:this.realwideHigh + 'px'
    }
    this.result.receive_time = momentFormat(this.result.receive_time,false,'-')
  }
  handleCopy(str) {
    copy(str);
    Toast.success("已复制");
  }
  @Emit()
  closePop() {
    document.body.style.overflow = "";
    return;
  }
}
</script>

<style lang="scss">
.card-reword {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  .content {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 40px 30px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #e1faf1 0%, #fafffd 100%);
    box-shadow: 0px 0px 10px 0px #001a19;
    border-radius: 16px;
    border: 1.5px solid rgba(255, 255, 255, 0.3);
    .close-pop {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
    }
    .card-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        display: block;
        height: 200px;
      }
      .title {
        font-size: 16px;
        color: black;
        font-weight: 800;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      color: #139796;
      font-size: 11px;
      opacity: 0.7;
    }
    .dashed {
      width: 100%;
      border: 1px dashed #b8e6d6;
      opacity: 0.4;
      margin-top: 20px;
    }
    .tips-pop{
        font-size: 12px;
        font-weight: 800;
        color: #139796;
        text-align: center;
        margin-top: 20px;
        opacity: 0.8;
    }
    .phone {
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #b8e6d6;
      display: flex;
      align-items: center;
      padding: 0 10px;
      justify-content: space-between;
      font-size: 13px;
      color: #139796;
      margin-top: 20px;
      .card-ser {
        font-weight: 800;
      }
      .copy {
        width: 52px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #139796;
        border: 1px solid #b7e5d5;
        border-radius: 24px;
      }
    }
  }
}
</style>