




















































































































































import { Component, Emit, Vue, Ref } from "vue-property-decorator";
import PlayFate from "@/views/weplay-game/components/PlayFate.vue";
import SupplyPackageApi, { ISupplyBox } from "@/api/supply-package.api";
import { SHARE_TEXT } from "@/constant";
import { observer } from "@/utils/common.util";
import SupplyPackageStateStore from "@/store/modules/supply-package-state.store";
import { getModule } from "vuex-module-decorators";
import RewordPop from "@/views/weplay-game/components/RewordPop.vue";
import CardReword from "@/views/weplay-game/components/CardReword.vue";
import CheckTips from "@/views/weplay-game/components/CheckTips.vue";
import { Toast } from "vant";
@Component({
  components: { PlayFate, RewordPop, CardReword, CheckTips },
})
export default class WeplayGame extends Vue {
  @Ref("play-fate")
  private readonly playfateRef: any;
  showPop = false;
  box_id = 2;
  supplyPackageState = getModule(SupplyPackageStateStore);
  detail: any;
  supplyBox: Array<ISupplyBox> = [];
  shufflingMessage: any;
  wideHigh = 0;
  checkPop = false;
  checkfalg = false
  jumpFlag = false
  offsetwidth = 0
  checkFalgActive = true
  async mounted(): Promise<void> {
    observer("BlindDetailInit", async () => {
      this.detail = await this.supplyPackageState.getSupplyDetail(this.box_id);
    });
    SupplyPackageApi.recordVisitLog(this.box_id);
    await this.initData();
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) - 24 - 60;
    this.offsetwidth = (clientWidth || offsetWidth) - 24
  }
  openPop() {
    this.showPop = true;
  }
  
  cancel() {
    this.checkPop = false;
  }
  async confirmCheck() {
     const toast = Toast.loading({ message: "查看中...", duration: 0 });
    this.checkPop = false;
     const result = await this.timesQuery(
        this.missFlag.phone,
        this.missFlag.passwo,
        this.$route.params.id
      ).catch((err) => {
        Toast(err.message);
        return undefined;
      });
      console.log("=>>>>>>", result);
      toast.close();
      if (result == undefined) return;
      this.result = result;
      this.RewordRecord = this.supplyBox.filter((item)=>{
        return item.id == result.supply_id
      })
      this.cardShow = true
  }
  closePop() {
    document.body.style.overflow = "";
    this.showPop = false;
  }
  closePopCard() {
    this.cardShow = false;
  }
  cardShow = false;
  openCard() {
    this.cardShow = true;
  }
  handleSkip() {
    this.playfateRef.playAnimation([this.result.supply_id]);
      this.RewordRecord = this.supplyBox.filter((item)=>{
        return item.id == this.result.supply_id
      })
      this.cardShow = true
      this.playfateRef.istrue = true
  }
  result:any = {};
  RewordRecord = {}
  missFlag:any
  async confirmPass(obj: any) {
    //确认领取
    this.result = {};
    const res = await SupplyPackageApi.PostWePlay(
      obj.phone,
      obj.passwo,
      this.$route.params.id
    );
    if (res.status == 0) {
      this.closePop();
      this.missFlag = obj
      this.checkPop = true;
    } else {
      const toast = Toast.loading({ message: "领取中...", duration: 0 });
      const result = await this.timesQuery(
        obj.phone,
        obj.passwo,
       this.$route.params.id
      ).catch((err) => {
        Toast(err.message);
        return undefined;
      });
      console.log("=>>>>>>", result);
      toast.close();
      if (result == undefined) return;
      this.result = result;
      this.closePop();
      this.playfateRef.istrue = false;
      this.playfateRef.playAnimation([result.supply_id]);
      this.RewordRecord = this.supplyBox.filter((item)=>{
        return item.id == result.supply_id
      })
    }
  }
  success(){
    this.cardShow = true
    this.playfateRef.istrue = true
  }
  async timesQuery(
    phone: string,
    code_str: string,
    code_value: string,
    i = 0
  ): Promise<any> {
    const queryResult = async () => {
      const result = await SupplyPackageApi.getWePlayRecord(
        phone,
        code_str,
        code_value
      );
      if (result.status === 1) return { status: true, data: result };
      if (result.status === 2) throw new Error(result.message);
      return { status: false, data: result };
    };
    const data = await queryResult();
    if (!data.status) {
      if (i > 15) throw new Error("数据请求发生异常");
      await new Promise<void>((e) => setTimeout(() => e(), 1000));
      return this.timesQuery(phone, code_str, code_value, i + 1);
    }
    return data.data;
  }

  async initData(): Promise<void> {
    this.supplyBox = await this.supplyPackageState.getWePlayBoxList();
  }
  checkabout(number:number) {
    if(this.checkFalgActive&&number == 0) return
    if(this.checkfalg&&number == 1) return
    this.checkFalgActive = !this.checkFalgActive
    this.checkfalg = !this.checkfalg
  }
}
