<template>
  <div class="reword-pop">
    <div class="content" :style="boxWidth">
        <img @click="closePop" src="https://vgn-images.oss-cn-beijing.aliyuncs.com/static/market/%E6%8C%89%E9%92%AE-%E5%85%B3%E9%97%AD%20%281%29.png" class="close-pop" alt="">
        <div class="phone" :style="heightStyle">
            <img class="phone-img" src="https://vgn-images.oss-cn-beijing.aliyuncs.com/static/market/%E5%9B%BE%E6%A0%87-%E6%89%8B%E6%9C%BA.png" alt="">
            <input type="text" v-model="phone" placeholder="请输入手机号" class="input">
        </div>
        <div class="tips-phone">*游戏激活码将发送至您输入的手机号上</div>
        <div class="phone" :style="heightStyle">
            <img class="phone-img" src="https://vgn-images.oss-cn-beijing.aliyuncs.com/static/market/%E5%9B%BE%E6%A0%87-%E9%AA%8C%E8%AF%81%E7%A0%81.png" alt="">
            <input v-model="passwo" type="text" placeholder="请输入领取码" class="input">
            <div :class="{sendCode:!verificationStyle,sendCodeActive:verificationStyle}" v-if="verification" @click="sendCodeToWeb" >
                发送验证码
            </div>
            <div v-else class="sendCodeAgain">{{timecountdown}}秒后重试</div>
        </div>
        <div class="confirm-reword" v-if="phone.length == 0||passwo.length == 0" >确认领取</div>
        <div class="confirm-rewords" v-else @click="confirmPass">确认领取</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Emit, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class RewordPop extends Vue {
    @Prop() wideHigh
    @Prop() offsetwidth
    phone = ''
    boxWidth = {}
    passwo = ''
    timecountdown = 60
    verification = true
    @Emit()
    confirmPass(){
        return {
            phone:this.phone,
            passwo:this.passwo
        }
    }
    get verificationStyle(){
        if(this.phone.length == 11){
            return false
        }
        return true
    }
    timer = null
    sendCodeToWeb(){
        if(!this.verificationStyle){
            this.timecountdown = 60
            this.verification = false
            this.timer = setInterval(()=>{
                if(this.timecountdown == 0){
                    clearInterval(this.timer)
                    this.verification = true
                }
                this.timecountdown--
            },1000)
        }else{
            console.log('无法点击')
        }
    }
    heightStyle = {
        height:0
    }
    shareStyle = {
        height:0
    }
    created(){
        this.boxWidth = {
            width:this.offsetwidth + 'px',
        }
        this.heightStyle.height = this.wideHigh*0.2 + 'px'
        this.shareStyle.height = this.wideHigh*0.6 + 'px'
        document.body.style.overflow = 'hidden'
    }
    @Emit()
    closePop() {
        document.body.style.overflow = ''
        return
    }
    deletePass(){
        this.passwo = ''
    }
    
}
</script>

<style lang="scss">
.sendCode{
    width: 80px;
    height: 44px;
    background: #00D1C2;
    border-radius: 4px;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    font-weight: 800;
    align-items: center;
}
.sendCodeActive{
    width: 80px;
    height: 44px;
    background: #00D1C2;
    border-radius: 4px;
    opacity: 0.4;
    font-weight: 800;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sendCodeAgain{
    width: 80px;
    height: 44px;
    border-radius: 4px;
    background: rgba($color: #D4D9D8, $alpha: 0.4);
    font-weight: 800;
    font-size: 12px;
    color: #969696;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reword-pop {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  .content {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 30px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #e1faf1 0%, #fafffd 100%);
    box-shadow: 0px 0px 10px 0px #001a19;
    border-radius: 16px;
    border: 1.5px solid rgba(255, 255, 255, 0.3);

    .close-pop{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
    }
    .phone{
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #B8E6D6;
        display: flex;
        align-items: center;
        padding:0 10px;
        position: relative;
        .phone-img{
            line-height: 60px;
            width: 20px;
            height: 20px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .close{
            position: absolute;
            right: 10px;
            width: 15px;
            height: 15px;
        }
        .input{
            height: 20px;
            width: 70%;
            font-size: 13px;
            font-weight: 600;
            color: #139796;
            border: none;
            margin-left: 10px;
            opacity: 0.7;
        }
        .input::placeholder{
                color: #139796;
            }
    }
    .tips-phone{
        font-size: 11px;
        font-weight: 400;
        color: #139796;
        margin: 5px 0 10px 0;
    }
    .confirm-reword{
        width: 100%;
        height: 44px;
        background: #00D1C2;
        box-shadow: 0px 5px 0px 0px #B8E6D6;
        border-radius: 22px;
        opacity: 0.4;
        border: 2px solid #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        margin: 20px 0;
    }
    .confirm-rewords{
        width: 100%;
        height: 44px;
        background: #00D1C2;
        box-shadow: 0px 5px 0px 0px #B8E6D6;
        border-radius: 22px;
        border: 2px solid #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 800;
        margin: 20px 0;
    }
    .dashed{
        width: 100%;
        border: 1px dashed #B8E6D6;
        opacity: 0.4;
    }
    .share-box{
        border: 1px solid #B7E5D5;
        height: 173px;
        width: 100%;
        background: white;
        margin-top: 20px;
    }
    .tips-app{
        color: #139796;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
    }
  }
}
</style>