




















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import DestinyCard from "@/views/weplay-game/destinyCard/DestinyCard.vue";
import { handleDataLen } from "@/utils/common.util";
import anime from "animejs";
import { pick } from "lodash";
import { ISupplyBox } from "@/api/supply-package.api";

@Component({
  components: {
    DestinyCard,
  },
})
export default class DestinyCartoon extends Vue {
  @Ref("__cartoon")
  private readonly cartoonRef!: HTMLDivElement;
  
  @Prop({ default: () => [] })
  private readonly items!: Array<ISupplyBox>;

  cardWideHigh = 120;
  animation = ["start"];
  animationStyle = {};
  _anime: anime.AnimeInstance | null = null;

  get isItems() {
    return this.items.length;
  }

  get cardList() {
    return handleDataLen(this.items, 100).map((row) => ({
      id: row.id,
      copy_id: `copy_${row.id}`,
      copy_last_id: `copy_last_${row.id}`,
      price: row.price / 100,
      title: row.title,
      cover: row.cover,
      character: row.level,
    }));
  }

  get cartoonStyle() {
    const jsxLength = this.cardList.length || 5;

    return {
      "--jsx-length": `${jsxLength * 10}s`,
      "--play-start": `${jsxLength * 0.1}s`,
      "--play-start-3": `${jsxLength * 0.1 * 3}s`,
    };
  }

  private getCardLeft(id: number) {
    const [{ $el }] = this.$refs[`destiny_card_${id}`] as unknown as {
      $el: HTMLDivElement;
    }[];
    return pick($el, ["offsetLeft", "scrollLeft", "clientLeft"]);
  }

  public async playAnimation(id: number, complete?: () => void) {
    this.animation = [];
    await this.$nextTick();
    const { offsetLeft, scrollLeft, clientLeft } = this.getCardLeft(id);
    const { clientWidth, offsetWidth } = this.cartoonRef;
    const left = offsetLeft || scrollLeft || clientLeft;
    const width = clientWidth || offsetWidth;
    const centreLine = width / 2;
    const cardWideHigh = this.cardWideHigh;
    const centreCard = cardWideHigh / 2;
    const centre = centreLine - centreCard;
    this._anime = anime({
      targets: ".__cartoon_item",
      translateX: -(left - centre),
      duration: 6000,
      easing: "cubicBezier(1, .97, 0, .97)",
      complete: () => {
        console.log("动画完成");
        this._anime = null;
        complete && complete();
      },
    });
  }

  public async stopAnimation() {
    this._anime?.pause();
    this._anime?.seek(6000);
  }

  public async restore() {
    this.animation = ["start"];
    anime({
      targets: ".__cartoon_item",
      translateX: 0,
      duration: 1,
    });
    await this.stopAnimation();
  }
}
